import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Input, Output, ViewChild, EventEmitter, AfterViewInit } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { response } from 'express';
import { ApiService } from 'src/app/api.service';
import { environment } from 'src/environments/environment';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.css']
})
export class ProjectListComponent implements AfterViewInit {
  @Input() dataSource: MatTableDataSource<PeriodicElement>;
  @Input() canView: boolean;
  @Input() canEdit: boolean;
  @Input() canDelete: boolean;
  @Input() canAssignCoordinator: boolean;
  @Input() canInitiateProject: boolean;
  @Input() canViewLogs: boolean;
  displayedColumns: string[] = ['project', 'client', 'regDate', 'status', 'type', 'coordinator', 'actions'];
  @Output() view = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() initiate = new EventEmitter<any>();
  @Output() assignUser = new EventEmitter<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  data: any;
  redirectUrl = environment.redirect.adminRedirectUrl
  idTokenData: any;
  accessTokenData: any;
  userInfo: any;
  role: any;
  emailId: string;
  projectCode: any = ''
  
  pageIndex: number = 0;
  pageSize: number = 5;
  totalItems: number = 0;

  
  @ViewChild(MatPaginator) matPaginator!: MatPaginator;
  @ViewChild(MatSort) matSort!: MatSort;

  constructor(private auth: AuthService, private service: ApiService, private router: Router, private http: HttpClient) {
  }

  ngOnInit() {
    console.log('this.tableData: ', this.dataSource);
    
    const params={
      page:(this.pageIndex+1).toString(),
      list_size:this.pageSize.toString()
    }
    this.getProjectList(params);

  }

  
  getProjectList(params:any){
    console.log(params);
    this.dataSource=new MatTableDataSource();
    this.service.getProjectList(params).subscribe((data) => {
      this.data = data

      this.totalItems=this.data.totalItems;
      this.dataSource = this.data.data;
      
      if (this.matPaginator) {
        this.dataSource.paginator = this.matPaginator;
      }
      this.dataSource.sort = this.matSort;

      this.auth.idTokenClaims$.subscribe(idToken => {
        this.idTokenData = idToken.__raw
        console.log(this.idTokenData)

        this.auth.getAccessTokenSilently().subscribe(accessToken => {
          this.accessTokenData = accessToken
        });
      })

    });

  }

  
  onPageChange(event:PageEvent){
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    const params={
      page:this.pageIndex+1,
      list_size:this.pageSize
    }
    this.getProjectList(params);
  }

  onDelete = (rowData: any) => {
    this.delete.emit(rowData);
    console.log(rowData)
  }

  onEdit = (rowData: any) => {
    this.edit.emit(rowData);
  }

  onView = (rowData: any) => {
    this.view.emit(rowData);
  }
  onInitiate = (rowData: any) => {
    this.initiate.emit(rowData);
  }


  onAssignUser = (rowData: any) => {
    this.assignUser.emit(rowData)
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  move() {
    let redirectLocation = `${this.redirectUrl}/dashboard`
    window.location.href = `${redirectLocation}`;
  }
  rowData(e: any) {
    console.log(e)
    console.log(e.project_code)
    let redirectLocation = `${this.redirectUrl}/project-details/${e.project_code}`
    console.log(redirectLocation)
    window.location.href = `${redirectLocation}`;
  }
  redirectToUrl(): void {
    // const params = new HttpParams().set('token', this.idTokenData).set('projectCode', this.projectCode)
    // console.log(params)
    //
    // this.router.navigateByUrl()

    // const myObject = {
    //   param1: `${this.idTokenData.__raw}`,
    //   param2: `${this.projectCode}`
    // };

    // const params = new HttpParams({ fromObject: myObject });
    // this.http.get('http://localhost:4200/project-detail', { params }).subscribe(response => {
    //   console.log(response)
    // });
  }

}
