import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '@auth0/auth0-angular';
import { ApiService } from 'src/app/api.service';
import { PeriodicElement } from '../project-list/project-list.component';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent {
  @Input() dataSource: MatTableDataSource<PeriodicElement>;
  @Input() canView: boolean;
  @Input() canEdit: boolean;
  @Input() canDelete: boolean;
  @Input() canAssignCoordinator: boolean;
  @Input() canInitiateProject: boolean;
  @Input() canViewLogs: boolean;
  displayedColumns: string[] = ['project', 'client', 'regDate', 'status', 'type', 'coordinator', 'actions'];
  @Output() view = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() initiate = new EventEmitter<any>();
  @Output() assignUser = new EventEmitter<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  datas: any;
  redirectUrl = environment.redirect.clientRedirectUrl;
  idTokenData: any;
  accessTokenData: any;
  clientInfo: any;
  
  pageIndex: number = 0;
  pageSize: number = 5;
  totalItems: number = 0;

  
  @ViewChild(MatPaginator) matPaginator!: MatPaginator;
  @ViewChild(MatSort) matSort!: MatSort;
  
  data: any;

  constructor(private auth: AuthService, private service: ApiService, private http: HttpClient, private router: Router) {
  }

  ngOnInit() {
    if(localStorage.getItem('role')==='Consultant'){
      this.redirectUrl=environment.redirect.consultantRedirectUrl;
    }
    if(localStorage.getItem('clientInfo')){
      this.clientInfo=localStorage.getItem('clientInfo');
    console.log('this.tableData: ', this.dataSource);
    let params = {
      page:(this.pageIndex+1).toString(),
      list_size:this.pageSize.toString(),
      client: this.clientInfo,
      status: 'INITIATED'
    }
    this.getProjectList(params);
    }
    else {
      window.location.href = environment.logoutUrl;
    }
  }

  getProjectList(params:any){
    console.log(params);
    this.dataSource=new MatTableDataSource();
    this.service.getProjectListByClient(params).subscribe((data) => {
      this.data = data

      this.totalItems=this.data.totalItems;
      this.dataSource = this.data.data;
      
      if (this.matPaginator) {
        this.dataSource.paginator = this.matPaginator;
      }
      this.dataSource.sort = this.matSort;

      this.auth.idTokenClaims$.subscribe(idToken => {
        this.idTokenData = idToken.__raw
        console.log(this.idTokenData)

        this.auth.getAccessTokenSilently().subscribe(accessToken => {
          this.accessTokenData = accessToken
        });
      })

    });

  }


  onPageChange(event:PageEvent){
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    const params={
      page:this.pageIndex+1,
      list_size:this.pageSize,
      status:'INITIATED',
      role:'Coordinator'
    }
    this.getProjectList(params);
  }


  onDelete = (rowData: any) => {
    this.delete.emit(rowData);
    console.log(rowData)
  }

  onEdit = (rowData: any) => {
    this.edit.emit(rowData);
  }

  onView = (rowData: any) => {
    this.view.emit(rowData);
  }
  onInitiate = (rowData: any) => {
    this.initiate.emit(rowData);
  }


  onAssignUser = (rowData: any) => {
    this.assignUser.emit(rowData)
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  move() {
    let redirectLocation = `${this.redirectUrl}`
    window.location.href = `${redirectLocation}`;
  }

  rowData(e: any) {
    console.log(e)
    console.log(e.project_code)
    let redirectLocation = `${this.redirectUrl}/section/${e.project_code}/project-details/`
    console.log(redirectLocation)
    window.location.href = `${redirectLocation}`;
  }
  redirectToUrl(): void {
    // const params = new HttpParams().set('token', this.idTokenData).set('projectCode', this.projectCode)
    // console.log(params)
    //
    // this.router.navigateByUrl()

    // const myObject = {
    //   param1: `${this.idTokenData.__raw}`,
    //   param2: `${this.projectCode}`
    // };

    // const params = new HttpParams({ fromObject: myObject });
    // this.http.get('http://localhost:4200/project-detail', { params }).subscribe(response => {
    //   console.log(response)
    // });
  }

}
